import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/index.css'

const firebaseConfig = {
  apiKey: 'AIzaSyC9gCYjaw0ULB0sphTldD6-sZ8JXIO5PXc',
  authDomain: 'bussring-84d5e.firebaseapp.com',
  projectId: 'bussring-84d5e',
  storageBucket: 'bussring-84d5e.appspot.com',
  messagingSenderId: '870614286780',
  appId: '1:870614286780:web:39b89b432a33e176c66bb9',
  measurementId: 'G-NKWQ2CHCQ8'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// eslint-disable-next-line
const analytics = getAnalytics(app)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
