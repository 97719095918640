import en from '../lang/en'
import no from '../lang/no'
import { ILang, TLang } from 'lang/_langType'
import { useLocation } from 'react-router'
import { IApiData, IDeparture } from 'typings/interfaces'

/**
 * Create a date object with added time for mockups
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 0.0.1
 * @param hours How many hours to add
 * @param minutes How many minutes to add
 * @returns The new date object
 * @since 0.0.1
 */
export function addToCurrentTime(hours: number, minutes: number = 0) {
  let newTime = new Date()
  newTime.setTime(newTime.getTime() + ((hours * 3600000) + (minutes * 60000)))
  return newTime
}

/**
 * Displays time until departure as a readable string.
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 0.0.1
 * @param time The departure time
 * @returns {string} The time until departure as a string
 * @since 0.0.1
 * 
 * TODO:
 * - Add language support
 */
export function displayCountdown(time: Date): string {
  const minutes = minutesUntilNow(time)
  // Display "Leaving soon" message
  if (minutes < 6) {
    return 'Under 5 min'
  }
  // Exclude hours
  if (minutes < 60) {
    return minutes + ' min'
  }
  // Exclude days
  if (minutes < 1440) {
    const hours = Math.floor(minutes / 60)
    return hours + ' t ' + (minutes % 60) + ' min'
  }
  return '24+ t'
}

/**
 * Fetches data from the Entur API.
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 1.0.0-alpha.4
 * @param query The GraphQL query to use
 * @returns The API data object
 * @since 0.0.1
 */
export async function getApiData(query: string) {
  const getData = () => fetch('https://api.entur.io/journey-planner/v3/graphql', {
    method: 'POST',
    headers: {
      'ET-Client-Name': 'bussring-digital_signage',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(stopPlaceData => {
      return stopPlaceData
    })
  
  return getData()
}

/**
 * Extracts a list of departures from the API data object.
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 0.0.1
 * @param data The API data object
 * @returns A list of departures
 * @since 0.0.1
 */
export function getDepartureList({ data }: IApiData) {
  let departureList: IDeparture[] = []
  data.stopPlace.estimatedCalls.forEach((departure) => {
    departureList.push({
      departureTime: new Date(departure.expectedDepartureTime),
      destination: departure.destinationDisplay.frontText,
      line: departure.serviceJourney.line.publicCode,
      transport: departure.serviceJourney.line.transportMode
    })
  })
  return departureList
}

/**
 * Calculates minutes untis Date.now()
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 0.0.1
 * @param time The future date time
 * @returns {number} minutes until Date.now()
 * @since 0.0.1
 */
export function minutesUntilNow(time: Date): number {
  const minutesLeft = (time.getTime() - Date.now()) / 60000
  return Math.round(minutesLeft)
}

/**
 * Sets what language to use.
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 0.0.1
 * @param language The language to use
 * @returns {Object} The requested language as an object
 * @since 0.0.1
 */
export function setLanguage(language: TLang): ILang {
  switch (language) {
    case 'no':
      return no
    default:
      return en
  }
}

/**
 * Converts Date time to 24 hour time format.
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 0.0.1
 * @param time The Date object to convert
 * @returns {string} The time in 24 hour format
 * @since 0.0.1
 */
export function to24hrClock(time: Date): string {
  return time.toLocaleTimeString('nb-NO', { hour: '2-digit', minute: '2-digit' })
}

/**
 * Extracts URL-query search parameters.
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 0.0.1
 * @returns {URLSearchParams} The object to get URL-query values from
 * @since 0.0.1
 */
export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}
