import React from 'react'
import { IDeparture } from 'typings/interfaces'
import { setLanguage } from 'lib/functions'
import { TLang } from 'lang/_langType'
import TableDataRow from './TableDataRow'
import './Table.css'
import Bus from 'components/svg/icons/Bus'
import Boat from 'components/svg/icons/Boat'
import PlaneDeparture from 'components/svg/icons/PlaneDeparture'
import Train from 'components/svg/icons/Train'

export interface TableProps {
  departureList: IDeparture[]
  language: TLang
}

/**
 * A component that displays departures as a table.
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 1.0.0
 * @param departureList A list of departure objects
 * @param language The language to use
 * @returns A table component
 * @since 0.0.1
 * 
 * TODO:
 * - Refactor code to use a single return
 */
function Table ({
  departureList,
  language
}: TableProps) {

  // Set language
  const lang = setLanguage(language)

  // Get icons
  function getIcon(transportMode: string) {
    const iconWidth = 28
    switch (transportMode) {
      case 'bus':
        return <Bus width={ iconWidth } />
      case 'water':
        return <Boat width={ iconWidth } />
      case 'air':
        return <PlaneDeparture width={ iconWidth } />
      case 'rail':
      case 'metro':
      case 'tram':
        return <Train width={ iconWidth } />
      default:
        return transportMode
    }
  }

  if (departureList.length > 0) {
    return (
      <table className="Table">
        <thead>
          <tr>
            <th>{ lang.LINE }</th>
            <th></th>
            <th>{ lang.DESTINATION }</th>
            <th className="center">{ lang.DEPARTURE_TIME }</th>
          </tr>
        </thead>
        <tbody>
          {/* Generate a row for every departure in list */}
          {departureList.map((departure: IDeparture, key: number) => {
            return (
              <TableDataRow
                departureTime={ departure.departureTime }
                destination={ departure.destination }
                key={ key }
                line={ departure.line }
                transport={ getIcon(departure.transport) }
              />
            )
          })}
        </tbody>
      </table>
    )
  }

  // Show loading message
  return <p>{ lang.AWAITING_STARTUP }</p>
}

export default Table