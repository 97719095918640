import React from 'react'
import { displayCountdown, setLanguage } from 'lib/functions'
import { TLang } from 'lang/_langType'
import './EtdCounter.css'

export interface EtdCounterProps {
  etd: Date,
  language: TLang
}

/**
 * A component that displays time until departure in large text.
 * @author CasperSocio <https://github.com/CasperSocio>
 * @version 1.0.0
 * @param etd The departure Date object
 * @param language The language to use
 * @returns A countdown component
 * @since 0.0.1
 */
function EtdCounter({
  etd,
  language
}: EtdCounterProps): JSX.Element {
  
  // Set language
  const lang = setLanguage(language)

  return (
    <div className="EtdCounter">
      <p>{ lang.NEXT_DEPARTURE }</p>
      <h2>{ displayCountdown(etd) }</h2>
    </div>
  )
}

export default EtdCounter
